<template>
    <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
      <Required_services />
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Required services',
components: {
  Required_services: defineAsyncComponent( () => import('@/components/Profile/Required_services.vue') ),
},
}
</script>